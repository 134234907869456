
import { Options, Vue } from "vue-class-component";
import CodeQuestWiki from "@/components/wikipages/CodeQuestWiki.vue";
import QuestionButton from "@/components/QuestionButton.vue";
import GuiWiki from "@/components/wikipages/GuiWiki.vue";

@Options({
  mounted() {
    //Scroll the opened wikipage into the view of the user
    var wikiItem = new URLSearchParams(window.location.search).get(
      "item"
    ) as string;
    let elem = document.getElementById(wikiItem);
    if (elem == null) return;
    elem.scrollIntoView({ behavior: "smooth", block: "start" });
  },
  components: {
    CodeQuestWiki,
    GuiWiki,
    QuestionButton,
  },
})
export default class Wiki extends Vue {}
