
import { Options, Vue } from "vue-class-component";

interface wikiPage {
  pageId: string;
  subject: string;
  description: [
    {
      parId: number;
      type: string;
      content: string;
    }
  ];
  show: boolean;
}

@Options({
  data() {
    return {
      wikiItems: [
        {
          pageId: "psdPage",
          subject: "wiki.psd",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.psdDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/PSD-voorbeeld.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "dragPage",
          subject: "wiki.drag",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.dragDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/drag.gif"),
            },
          ],
          show: false,
        },
        {
          pageId: "savePage",
          subject: "wiki.save",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.saveDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/save.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "testPage",
          subject: "wiki.test",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.testDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/testing.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "breakPage",
          subject: "wiki.break",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.breakDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/breakpoints.png"),
            },
            {
              parId: 3,
              type: "text",
              content: "wiki.breakDes2",
            },
          ],
          show: false,
        },
        {
          pageId: "stringPage",
          subject: "wiki.string",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.stringDes",
            },
          ],
          show: false,
        },
        {
          pageId: "boolPage",
          subject: "wiki.bool",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.boolDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/boolean.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "dataPage",
          subject: "wiki.data",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.dataDes",
            },
          ],
          show: false,
        },
        {
          pageId: "varPage",
          subject: "wiki.var",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.varDes",
            },
            {
              parId: 2,
              type: "text",
              content: "wiki.varDes2",
            },
          ],
          show: false,
        },
        {
          pageId: "enumPage",
          subject: "wiki.enum",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.enumDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/enum.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "newvarPage",
          subject: "wiki.newvar",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.newvarDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/newvar.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "calcPage",
          subject: "wiki.calc",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.calcDes",
            },
          ],
          show: false,
        },
        {
          pageId: "parPage",
          subject: "wiki.par",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.parDes",
            },
          ],
          show: false,
        },
        {
          pageId: "binPage",
          subject: "wiki.bin",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.binDes",
            },
          ],
          show: false,
        },
        {
          pageId: "assiPage",
          subject: "wiki.assi",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.assiDes",
            },
            {
              parId: 2,
              type: "text",
              content: "wiki.assiDes2",
            },
          ],
          show: false,
        },
        {
          pageId: "checkPage",
          subject: "wiki.check",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.checkDes",
            },
            {
              parId: 2,
              type: "text",
              content: "wiki.checkDes2",
            },
            {
              parId: 3,
              type: "text",
              content: "wiki.checkDes3",
            },
            {
              parId: 4,
              type: "text",
              content: "wiki.checkDes4",
            },
            {
              parId: 5,
              type: "text",
              content: "wiki.checkDes5",
            },
          ],
          show: false,
        },
        {
          pageId: "switchPage",
          subject: "wiki.switch",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.switchDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/switch.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "condiPage",
          subject: "wiki.condi",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.condiDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/condition.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "andPage",
          subject: "wiki.and",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.andDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/and.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "orPage",
          subject: "wiki.or",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.orDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/or.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "exorPage",
          subject: "wiki.exor",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.exorDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/exor.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "notPage",
          subject: "wiki.not",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.notDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/not.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "ifPage",
          subject: "wiki.if",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.ifDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/ifelse.png"),
            },
            {
              parId: 3,
              type: "text",
              content: "wiki.ifDes2",
            },
          ],
          show: false,
        },
        {
          pageId: "whilePage",
          subject: "wiki.while",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.whileDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/while.png"),
            },
            {
              parId: 3,
              type: "text",
              content: "wiki.whileDes2",
            },
          ],
          show: false,
        },
        {
          pageId: "writePage",
          subject: "wiki.write",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.writeDes",
            },
            {
              parId: 2,
              type: "text",
              content: "wiki.writeDes2",
            },
            {
              parId: 3,
              type: "text",
              content: "wiki.writeDes3",
            },
            {
              parId: 4,
              type: "image",
              content: require("@/assets/images/wikipages/writevoorbeeld.png"),
            },
            {
              parId: 5,
              type: "text",
              content: "wiki.writeDes4",
            },
          ],
          show: false,
        },
        {
          pageId: "powPage",
          subject: "wiki.pow",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.powDes",
            },
          ],
          show: false,
        },
        {
          pageId: "modPage",
          subject: "wiki.mod",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.modDes",
            },
            {
              parId: 2,
              type: "text",
              content: "wiki.modDes2",
            },
            {
              parId: 3,
              type: "video",
              content: "https://www.youtube.com/embed/l0DWLBX5d2A",
            },
          ],
          show: false,
        },
        {
          pageId: "infPage",
          subject: "wiki.inf",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.infDes",
            },
          ],
          show: false,
        },
      ],
    };
  },
  beforeMount() {
    var wikiItem = new URLSearchParams(window.location.search).get(
      "item"
    ) as string;
    //Check if the user wanted to see a specific wikipage
    for (let i = 0; i < this.wikiItems.length; i++) {
      if (wikiItem == this.wikiItems[i].pageId) {
        this.wikiItems[i].show = true;
      }
    }
  },
  methods: {
    /**
     * Toggle the shown wiki element
     * @param wikiPage Selected wiki page
     */
    togglePage(wikiPage: wikiPage) {
      wikiPage.show = !wikiPage.show;
    },
  },
})
export default class CodeQuestWiki extends Vue {}
