
import { Options, Vue } from "vue-class-component";

interface wikiPage {
  pageId: string;
  subject: string;
  description: [
    {
      parId: number;
      type: string;
      content: string;
    }
  ];
  show: boolean;
}

@Options({
  data() {
    return {
      wikiItems: [
        {
          pageId: "codequestPage",
          subject: "wiki.codequest",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.codequestDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/wikilogo.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "loginPage",
          subject: "wiki.login",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.loginDes",
            },
          ],
          show: false,
        },
        {
          pageId: "profilePage",
          subject: "wiki.profile",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.profileDes",
            },
          ],
          show: false,
        },
        {
          pageId: "avatarPage",
          subject: "wiki.avatar",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.avatarDes",
            },
          ],
          show: false,
        },
        {
          pageId: "settingPage",
          subject: "wiki.setting",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.settingDes",
            },
          ],
          show: false,
        },
        {
          pageId: "langPage",
          subject: "wiki.lang",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.langDes",
            },
          ],
          show: false,
        },
        {
          pageId: "progwayPage",
          subject: "wiki.progway",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.progwayDes",
            },
          ],
          show: false,
        },
        {
          pageId: "levelselPage",
          subject: "wiki.levelsel",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.levelselDes",
            },
          ],
          show: false,
        },
        {
          pageId: "levclosePage",
          subject: "wiki.levclose",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.levcloseDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/lockedlevels.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "medalsPage",
          subject: "wiki.medals",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.medalsDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/medals.png"),
            },
          ],
          show: false,
        },
        {
          pageId: "statsPage",
          subject: "wiki.stats",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.statsDes",
            },
          ],
          show: false,
        },
        {
          pageId: "achevPage",
          subject: "wiki.achev",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.achevDes",
            },
          ],
          show: false,
        },
        {
          pageId: "botzQPage",
          subject: "wiki.botzQ",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.botzQDes",
            },
            {
              parId: 2,
              type: "video",
              content: "https://www.youtube.com/embed/YTg8KgtcWmk",
            },
          ],
          show: false,
        },
        {
          pageId: "quest2DPage",
          subject: "wiki.quest2D",
          description: [
            {
              parId: 1,
              type: "text",
              content: "wiki.quest2DDes",
            },
            {
              parId: 2,
              type: "image",
              content: require("@/assets/images/wikipages/Team.png"),
            },
          ],
          show: false,
        },
        //here more pages can be added
      ],
    };
  },

  beforeMount() {
    this.teacherSpecificPages();

    var wikiItem = new URLSearchParams(window.location.search).get(
      "item"
    ) as string;
    //Check if the user wanted to see a specific wikipage
    for (let i = 0; i < this.wikiItems.length; i++) {
      if (wikiItem == this.wikiItems[i].pageId) {
        this.wikiItems[i].show = true;
      }
    }
  },
  methods: {
    /**
     * Toggle the shown wiki element
     * @param wikiPage Selected wiki page
     */
    togglePage(wikiPage: wikiPage) {
      wikiPage.show = !wikiPage.show;
    },
    //adds extra pages when user is a teacher or admin
    teacherSpecificPages() {
      let permLevel = this.$store.getters.getUserInfo.permissionLevel;
      if (permLevel == "teacher" || permLevel == "admin") {
        this.wikiItems.push(
          {
            pageId: "classWikiPage",
            subject: "wiki.classWiki",
            description: [
              {
                parId: 1,
                type: "text",
                content: "wiki.classWikiDes",
              },
              {
                parId: 2,
                type: "image",
                content: require("@/assets/images/wikipages/classpage.png"),
              },
              {
                parId: 3,
                type: "text",
                content: "wiki.classWikiDes2",
              },
            ],
            show: false,
          },
          {
            pageId: "classignPage",
            subject: "wiki.classign",
            description: [
              {
                parId: 1,
                type: "text",
                content: "wiki.classignDes",
              },
              {
                parId: 2,
                type: "image",
                content: require("@/assets/images/wikipages/bundelselect.png"),
              },
              {
                parId: 3,
                type: "text",
                content: "wiki.classignDes2",
              },
              {
                parId: 4,
                type: "image",
                content: require("@/assets/images/wikipages/bundelsave.png"),
              },
            ],
            show: false,
          },
          {
            pageId: "packWikiPage",
            subject: "wiki.packWiki",
            description: [
              {
                parId: 1,
                type: "text",
                content: "wiki.packWikiDes",
              },
              {
                parId: 2,
                type: "text",
                content: "wiki.packWikiDes2",
              },
              {
                parId: 3,
                type: "text",
                content: "wiki.packWikiDes3",
              },
            ],
            show: false,
          },

          {
            pageId: "levMakerPage",
            subject: "wiki.levMaker",
            description: [
              {
                parId: 1,
                type: "text",
                content: "wiki.levMakerDes",
              },
              {
                parId: 2,
                type: "image",
                content: require("@/assets/images/wikipages/levelmakername.png"),
              },
              {
                parId: 3,
                type: "text",
                content: "wiki.levMakerDes2",
              },
              {
                parId: 4,
                type: "image",
                content: require("@/assets/images/wikipages/levelmakerblocks.png"),
              },
              {
                parId: 5,
                type: "text",
                content: "wiki.levMakerDes3",
              },
              {
                parId: 6,
                type: "image",
                content: require("@/assets/images/wikipages/levelmakerraster.png"),
              },
              {
                parId: 7,
                type: "text",
                content: "wiki.levMakerDes4",
              },
              {
                parId: 8,
                type: "image",
                content: require("@/assets/images/wikipages/levelmakeroptions.png"),
              },
            ],
            show: false,
          }
          //here more pages can be added
        );
      }
    },
  },
})
export default class GuiWiki extends Vue {}
